import React from 'react'
import Layout from '../components/layout'

const TheMRCWayPage = () => {
  return (
    <Layout pageTitle="The MRC Way (Code of Ethics)" title="The MRC Way (Code of Ethics)" description="Team MRC stands for an uncompromising approach when it comes to conduct and dealings.">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <p data-sal="fade" data-sal-easing="ease-out">Team MRC stands for an uncompromising approach when it comes to conduct and dealings. Some of the ideas espoused by team are:</p>
          <h2 data-sal="fade" data-sal-delay="100" data-sal-easing="ease-out">Clean Deals</h2>
          <p data-sal="fade" data-sal-delay="150" data-sal-easing="ease-out">All deals done at MRC will carry the trust of the brand’s legacy. No aspect of a deal is to weaken a party or favour another. All deals need to be unbiased, systematic and process- driven free of any malpractices whatsoever.</p>
          <h2 data-sal="fade" data-sal-delay="200" data-sal-easing="ease-out">Transparency in process</h2>
          <p data-sal="fade" data-sal-delay="250" data-sal-easing="ease-out">Team MRC is committed to establish absolute clarity in all dealings while taking care of interests of all parties involved. At MRC, emphasis is laid upon setting expectations right, and conveying possible outcomes before initiating a deal.</p>
          <h2 data-sal="fade" data-sal-delay="300" data-sal-easing="ease-out">Relationship First</h2>
          <p data-sal="fade" data-sal-delay="350" data-sal-easing="ease-out">Profit and shareholder value are important benchmarks for any business, but at MRC it is the relationships that matter the most. MRC Agrotech performs well and succeeds because of the strength of relationships with farmers, buyers and investors. The care and concern shown by team members is what sets MRC apart from other players in the market. The thrust on relationships can only grow at MRC Agrotech.</p>
        </div>
      </div>
    </Layout>
  )
}

export default TheMRCWayPage
